import React from 'react'
// import { Switch, Route, Redirect } from 'react-router'
import { Switch, Route } from 'react-router'
import loadable from '@loadable/component'

import { LANGUAGES } from '../_i18n/languages'
import parseTimerFromPath from '../timer/parseTimerFromPath'

const Terms = loadable(() => import('../terms/Terms'))
const Privacy = loadable(() => import('../privacy/Privacy'))
const NotFound = loadable(() => import('../notFound/NotFound'))
const Stopwatch = loadable(() => import('../stopwatch/Stopwatch'))
const Timer = loadable(() => import('../timer/Timer'))

const MultiLanguageRoute = (props) => {
    //const defaultLanguage = LANGUAGES.en.urlLang
    let hasLang = props.computedMatch.params.lang
    hasLang = hasLang ? hasLang : 'en'
    const is404Page = props.location.pathname.split('/')[3]
    //const isBasePathWithoutLang = props.location.pathname === "/"
    const currentLanguage = LANGUAGES[hasLang]

    //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
    //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
    let staticContext = props.staticContext
    if (!currentLanguage || is404Page) return <Route render={() => <NotFound staticContext={staticContext} />} />

    let ptfp = parseTimerFromPath(props)
    if ((typeof ptfp === 'boolean') && (!ptfp)) return <Route render={() => <NotFound staticContext={staticContext} />} />

    return <Route {...props} />
}

const router = (props) => (
    <React.Fragment>
        <Switch>
            <Route exact path='/' component={Timer} />
            <Route path='/terms' component={Terms} />
            <Route path='/privacy' component={Privacy} />
            {/* <Route path='/timers' render={(props) => <Home {...props} custom={{
                'title': '',
                'description': ''
            }} />} /> */}
            <MultiLanguageRoute path='/stopwatch/:lang?' {...props} component={Stopwatch} />
            <MultiLanguageRoute path='/:lang' {...props} component={Timer} />
            <Route {...props} component={NotFound} />
        </Switch>
    </React.Fragment>
)


export default router