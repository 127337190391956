import React from 'react'

import Header from '../common/template/header'
import Footer from '../common/template/footer'
import Routes from './routes'
import AdSense from 'react-adsense';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import Container from '@mui/material/Container';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA5N4m6V67G4_yeIAT7N7CcB5oxW8T-8XM",
    authDomain: "online-timer-18277.firebaseapp.com",
    projectId: "online-timer-18277",
    storageBucket: "online-timer-18277.appspot.com",
    messagingSenderId: "331418250605",
    appId: "1:331418250605:web:0ff7094f1f039cb8413e64",
    measurementId: "G-2BTVP5D6VQ"
};

const theme = createTheme(
    {
        "breakpoints": {
            "keys": [
                "xs",
                "sm",
                "md",
                "lg",
                "xl"
            ],
            "values": {
                "xs": 0,
                "sm": 600,
                "md": 900,
                "lg": 1200,
                "xl": 1536
            },
            "unit": "px"
        },
        "direction": "ltr",
        "components": {
            "MuiCssBaseline": {
                "defaultProps": {
                    "enableColorScheme": true
                }
            },
            "MuiBreadcrumbs": {
                "defaultProps": {
                    "expandText": "Mostrar caminho"
                }
            },
            "MuiTablePagination": {
                "defaultProps": {
                    "labelRowsPerPage": "Linhas por página:"
                }
            },
            "MuiRating": {
                "defaultProps": {
                    "emptyLabelText": "Vazio"
                }
            },
            "MuiAutocomplete": {
                "defaultProps": {
                    "clearText": "Limpar",
                    "closeText": "Fechar",
                    "loadingText": "Carregando…",
                    "noOptionsText": "Sem opções",
                    "openText": "Abrir"
                }
            },
            "MuiAlert": {
                "defaultProps": {
                    "closeText": "Fechar"
                }
            },
            "MuiPagination": {
                "defaultProps": {
                    "aria-label": "Navegar pela paginação"
                }
            },
            "MuiButtonBase": {
                "defaultProps": {
                    "disableTouchRipple": true
                }
            },
            "MuiButton": {
                "defaultProps": {
                    "disableElevation": true
                },
                "styleOverrides": {
                    "sizeLarge": {
                        "padding": "0.875rem 1rem",
                        "fontSize": "1rem",
                        "lineHeight": 1.3125,
                        "letterSpacing": 0,
                        "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                        "fontWeight": 700
                    },
                    "sizeSmall": {
                        "padding": "4px 8px",
                        "marginLeft": "-8px"
                    },
                    "containedPrimary": {
                        "backgroundColor": "#007FFF",
                        "color": "#fff"
                    }
                },
                "variants": [
                    {
                        "props": {
                            "variant": "code"
                        },
                        "style": {
                            "color": "#2D3843",
                            "border": "1px solid",
                            "borderColor": "#CDD2D7",
                            "backgroundColor": "#F3F6F9",
                            "fontFamily": "Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono,monospace",
                            "fontWeight": 400,
                            "fontSize": "0.8125rem",
                            "lineHeight": 1.5,
                            "letterSpacing": 0,
                            "WebkitFontSmoothing": "subpixel-antialiased",
                            "&:hover, &.Mui-focusVisible": {
                                "borderColor": "#007FFF",
                                "backgroundColor": "#F0F7FF",
                                "& .MuiButton-endIcon": {
                                    "color": "#007FFF"
                                }
                            },
                            "& .MuiButton-startIcon": {
                                "color": "#B2BAC2"
                            },
                            "& .MuiButton-endIcon": {
                                "display": "inline-block",
                                "position": "absolute",
                                "right": 0,
                                "marginRight": 10,
                                "color": "#3E5060"
                            }
                        }
                    },
                    {
                        "props": {
                            "variant": "link"
                        },
                        "style": {
                            "fontSize": "0.875rem",
                            "fontWeight": 700,
                            "color": "#0072E5",
                            "mb": 1,
                            "& svg": {
                                "ml": -0.5
                            }
                        }
                    }
                ]
            },
            "MuiIconButton": {
                "variants": [
                    {
                        "props": {
                            "color": "primary"
                        },
                        "style": {
                            "height": 34,
                            "width": 34,
                            "border": "1px solid #E0E3E7",
                            "borderRadius": 10,
                            "color": "#007FFF",
                            "&:hover": {
                                "borderColor": "#CDD2D7",
                                "background": "#F3F6F9"
                            }
                        }
                    }
                ]
            },
            "MuiMenu": {
                "styleOverrides": {
                    "paper": {
                        "mt": 0.5,
                        "minWidth": 160,
                        "elevation": 0,
                        "color": "#3E5060",
                        "backgroundImage": "none",
                        "backgroundColor": "#fff",
                        "border": "1px solid #E0E3E7",
                        "& .MuiMenuItem-root": {
                            "fontSize": "0.875rem",
                            "fontWeight": 500,
                            "&:hover": {
                                "backgroundColor": "#F3F6F9"
                            },
                            "&:focus": {
                                "backgroundColor": "#F3F6F9"
                            },
                            "&.Mui-selected": {
                                "fontWeight": 500,
                                "color": "#0072E5",
                                "backgroundColor": "rgba(194, 224, 255, 0.6)"
                            }
                        }
                    }
                }
            },
            "MuiPopover": {
                "styleOverrides": {
                    "paper": {
                        "boxShadow": "0px 4px 20px rgba(170, 180, 190, 0.3)"
                    }
                }
            },
            "MuiContainer": {
                "styleOverrides": {
                    "root": {
                        "@media (min-width:900px)": {
                            "paddingLeft": "16px",
                            "paddingRight": "16px"
                        }
                    }
                }
            },
            "MuiDivider": {
                "styleOverrides": {
                    "root": {
                        "borderColor": "#E7EBF0"
                    }
                }
            },
            "MuiLink": {
                "defaultProps": {
                    "underline": "none"
                },
                "styleOverrides": {
                    "root": {
                        "color": "#0072E5",
                        "fontWeight": 700,
                        "display": "inline-flex",
                        "alignItems": "center",
                        "&:hover": {
                            "color": "#0059B2"
                        },
                        "&.MuiTypography-body1 > svg": {
                            "marginTop": 2
                        },
                        "& svg:last-child": {
                            "marginLeft": 2
                        }
                    }
                }
            },
            "MuiChip": {
                "styleOverrides": {
                    "deleteIcon": {
                        "color": "#0059B2",
                        "&:hover": {
                            "color": "#003A75"
                        }
                    }
                }
            },
            "MuiList": {
                "styleOverrides": {
                    "root": {
                        "padding": 0
                    }
                }
            },
            "MuiListItemButton": {
                "styleOverrides": {
                    "root": {
                        "padding": "8px",
                        "textTransform": "none",
                        "fontWeight": 500,
                        "fontSize": "0.875rem",
                        "color": "#3E5060",
                        "borderRadius": 0,
                        "&:hover": {
                            "backgroundColor": "#F3F6F9"
                        },
                        "&.Mui-selected": {
                            "color": "#007FFF",
                            "borderRadius": 10,
                            "border": "1px solid",
                            "borderColor": "#007FFF !important",
                            "backgroundColor": "#F0F7FF",
                            "&:hover": {
                                "backgroundColor": "#C2E0FF"
                            }
                        }
                    }
                }
            },
            "MuiSelect": {
                "defaultProps": {
                    "IconComponent": {
                        "type": {},
                        "compare": null
                    }
                },
                "styleOverrides": {
                    "iconFilled": {
                        "top": "calc(50% - .25em)"
                    }
                }
            },
            "MuiTab": {
                "defaultProps": {
                    "disableTouchRipple": true
                }
            },
            "MuiPaper": {
                "styleOverrides": {
                    "root": {
                        "backgroundImage": "none",
                        "backgroundColor": "#fff",
                        "&[href]": {
                            "textDecorationLine": "none"
                        }
                    },
                    "outlined": {
                        "display": "block",
                        "borderColor": "#E0E3E7",
                        "a&, button&": {
                            "&:hover": {
                                "boxShadow": "0px 4px 20px rgba(170, 180, 190, 0.3)"
                            }
                        }
                    }
                }
            },
            "MuiTableCell": {
                "styleOverrides": {
                    "root": {
                        "padding": "8px 16px",
                        "borderColor": "#E7EBF0"
                    },
                    "head": {
                        "color": "#1A2027",
                        "fontWeight": 700
                    },
                    "body": {
                        "color": "#3E5060"
                    }
                }
            },
            "MuiToggleButtonGroup": {
                "styleOverrides": {
                    "root": {
                        "backgroundColor": "#fff"
                    }
                }
            },
            "MuiToggleButton": {
                "styleOverrides": {
                    "root": {
                        "textTransform": "none",
                        "fontWeight": 500,
                        "color": "#3E5060",
                        "borderColor": "#E0E3E7",
                        "&.Mui-selected": {
                            "color": "#007FFF",
                            "borderColor": "#007FFF !important",
                            "backgroundColor": "#F0F7FF",
                            "&:hover": {
                                "backgroundColor": "#C2E0FF"
                            }
                        }
                    }
                }
            },
            "MuiTooltip": {
                "styleOverrides": {
                    "tooltip": {
                        "padding": "5px 9px"
                    }
                }
            },
            "MuiSwitch": {
                "styleOverrides": {
                    "root": {
                        "width": 32,
                        "height": 20,
                        "padding": 0,
                        "& .MuiSwitch-switchBase": {
                            "&.Mui-checked": {
                                "transform": "translateX(11px)",
                                "color": "#fff"
                            }
                        }
                    },
                    "switchBase": {
                        "height": 20,
                        "width": 20,
                        "padding": 0,
                        "color": "#fff",
                        "&.Mui-checked + .MuiSwitch-track": {
                            "opacity": 1
                        }
                    },
                    "track": {
                        "opacity": 1,
                        "borderRadius": 32,
                        "backgroundColor": "#B2BAC2"
                    },
                    "thumb": {
                        "flexShrink": 0,
                        "width": "14px",
                        "height": "14px"
                    }
                }
            },
            "MuiPaginationItem": {
                "styleOverrides": {
                    "root": {
                        "textTransform": "none",
                        "fontWeight": 700,
                        "color": "#3E5060",
                        "borderColor": "#E0E3E7",
                        "&.Mui-selected": {
                            "color": "#007FFF",
                            "borderColor": "#007FFF !important",
                            "backgroundColor": "#F0F7FF",
                            "&:hover": {
                                "backgroundColor": "#C2E0FF"
                            }
                        }
                    }
                }
            }
        },
        "palette": {
            "mode": "light",
            "primary": {
                "50": "#F0F7FF",
                "100": "#C2E0FF",
                "200": "#99CCF3",
                "300": "#66B2FF",
                "400": "#3399FF",
                "500": "#007FFF",
                "600": "#0072E5",
                "700": "#0059B2",
                "800": "#004C99",
                "900": "#003A75",
                "main": "#007FFF",
                "light": "#66B2FF",
                "dark": "#0059B2",
                "contrastText": "#fff"
            },
            "divider": "#E7EBF0",
            "primaryDark": {
                "50": "#E2EDF8",
                "100": "#CEE0F3",
                "200": "#91B9E3",
                "300": "#5090D3",
                "400": "#265D97",
                "500": "#1E4976",
                "600": "#173A5E",
                "700": "#132F4C",
                "800": "#001E3C",
                "900": "#0A1929",
                "main": "#5090D3"
            },
            "common": {
                "black": "#1D1D1D",
                "white": "#fff"
            },
            "text": {
                "primary": "#1A2027",
                "secondary": "#3E5060",
                "disabled": "rgba(0, 0, 0, 0.38)"
            },
            "grey": {
                "50": "#F3F6F9",
                "100": "#E7EBF0",
                "200": "#E0E3E7",
                "300": "#CDD2D7",
                "400": "#B2BAC2",
                "500": "#A0AAB4",
                "600": "#6F7E8C",
                "700": "#3E5060",
                "800": "#2D3843",
                "900": "#1A2027",
                "A100": "#f5f5f5",
                "A200": "#eeeeee",
                "A400": "#bdbdbd",
                "A700": "#616161"
            },
            "error": {
                "50": "#FFF0F1",
                "100": "#FFDBDE",
                "200": "#FFBDC2",
                "300": "#FF99A2",
                "400": "#FF7A86",
                "500": "#FF505F",
                "600": "#EB0014",
                "700": "#C70011",
                "800": "#94000D",
                "900": "#570007",
                "main": "#EB0014",
                "light": "#FF99A2",
                "dark": "#C70011",
                "contrastText": "#fff"
            },
            "success": {
                "50": "#E9FBF0",
                "100": "#C6F6D9",
                "200": "#9AEFBC",
                "300": "#6AE79C",
                "400": "#3EE07F",
                "500": "#21CC66",
                "600": "#1DB45A",
                "700": "#1AA251",
                "800": "#178D46",
                "900": "#0F5C2E",
                "main": "#1AA251",
                "light": "#6AE79C",
                "dark": "#1AA251",
                "contrastText": "#fff"
            },
            "warning": {
                "50": "#FFF9EB",
                "100": "#FFF3C1",
                "200": "#FFECA1",
                "300": "#FFDC48",
                "400": "#F4C000",
                "500": "#DEA500",
                "600": "#D18E00",
                "700": "#AB6800",
                "800": "#8C5800",
                "900": "#5A3600",
                "main": "#DEA500",
                "light": "#FFDC48",
                "dark": "#AB6800",
                "contrastText": "rgba(0, 0, 0, 0.87)"
            },
            "secondary": {
                "main": "#9c27b0",
                "light": "#ba68c8",
                "dark": "#7b1fa2",
                "contrastText": "#fff"
            },
            "info": {
                "main": "#0288d1",
                "light": "#03a9f4",
                "dark": "#01579b",
                "contrastText": "#fff"
            },
            "contrastThreshold": 3,
            "tonalOffset": 0.2,
            "background": {
                "paper": "#fff",
                "default": "#fff"
            },
            "action": {
                "active": "rgba(0, 0, 0, 0.54)",
                "hover": "rgba(0, 0, 0, 0.04)",
                "hoverOpacity": 0.04,
                "selected": "rgba(0, 0, 0, 0.08)",
                "selectedOpacity": 0.08,
                "disabled": "rgba(0, 0, 0, 0.26)",
                "disabledBackground": "rgba(0, 0, 0, 0.12)",
                "disabledOpacity": 0.38,
                "focus": "rgba(0, 0, 0, 0.12)",
                "focusOpacity": 0.12,
                "activatedOpacity": 0.12
            }
        },
        "shape": {
            "borderRadius": 10
        },
        "unstable_strictMode": true,
        "typography": {
            "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontFamilyCode": "Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono,monospace",
            "fontFamilyTagline": "\"PlusJakartaSans-ExtraBold\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontFamilySystem": "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontWeightSemiBold": 600,
            "fontWeightExtraBold": 800,
            "h1": {
                "fontFamily": "\"PlusJakartaSans-ExtraBold\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontSize": "clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)",
                "fontWeight": 800,
                "lineHeight": 1.1142857142857143,
                "color": "#0A1929"
            },
            "h2": {
                "fontFamily": "\"PlusJakartaSans-ExtraBold\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontSize": "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
                "fontWeight": 800,
                "lineHeight": 1.2222222222222223,
                "color": "#132F4C"
            },
            "h3": {
                "fontFamily": "\"PlusJakartaSans-Bold\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontSize": "2.25rem",
                "lineHeight": 1.2222222222222223,
                "letterSpacing": 0.2,
                "fontWeight": 400
            },
            "h4": {
                "fontFamily": "\"PlusJakartaSans-Bold\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontSize": "1.75rem",
                "lineHeight": 1.5,
                "letterSpacing": 0.2,
                "fontWeight": 400
            },
            "h5": {
                "fontFamily": "\"PlusJakartaSans-Bold\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontSize": "1.5rem",
                "lineHeight": 1.5,
                "letterSpacing": 0.1,
                "color": "#007FFF",
                "fontWeight": 400
            },
            "h6": {
                "fontSize": "1.25rem",
                "lineHeight": 1.5,
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontWeight": 500
            },
            "button": {
                "textTransform": "initial",
                "fontWeight": 700,
                "letterSpacing": 0,
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontSize": "0.875rem",
                "lineHeight": 1.75
            },
            "subtitle1": {
                "fontSize": "1.125rem",
                "lineHeight": 1.3333333333333333,
                "letterSpacing": 0,
                "fontWeight": 500,
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""
            },
            "body1": {
                "fontSize": "1rem",
                "lineHeight": 1.5,
                "letterSpacing": 0,
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontWeight": 400
            },
            "body2": {
                "fontSize": "0.875rem",
                "lineHeight": 1.5,
                "letterSpacing": 0,
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontWeight": 400
            },
            "caption": {
                "display": "inline-block",
                "fontSize": "0.75rem",
                "lineHeight": 1.5,
                "letterSpacing": 0,
                "fontWeight": 700,
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""
            },
            "htmlFontSize": 16,
            "fontSize": 14,
            "fontWeightLight": 300,
            "fontWeightRegular": 400,
            "fontWeightMedium": 500,
            "fontWeightBold": 700,
            "subtitle2": {
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontWeight": 500,
                "fontSize": "0.875rem",
                "lineHeight": 1.57
            },
            "overline": {
                "fontFamily": "\"IBM Plex Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                "fontWeight": 400,
                "fontSize": "0.75rem",
                "lineHeight": 2.66,
                "textTransform": "uppercase"
            }
        },
        "nprogress": {
            "color": "#007FFF"
        },
        "props": {
            "MuiBadge": {
                "overlap": "rectangular"
            }
        },
        "mixins": {
            "toolbar": {
                "minHeight": 56,
                "@media (min-width:0px)": {
                    "@media (orientation: landscape)": {
                        "minHeight": 48
                    }
                },
                "@media (min-width:600px)": {
                    "minHeight": 64
                }
            }
        },
        "shadows": [
            "none",
            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
            "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
            "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
            "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
            "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
            "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
            "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
            "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
            "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
            "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
            "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
            "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
            "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
            "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
            "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
            "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
            "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
            "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
            "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
            "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
            "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
        ],
        "transitions": {
            "easing": {
                "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
                "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
                "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
                "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
            },
            "duration": {
                "shortest": 150,
                "shorter": 200,
                "short": 250,
                "standard": 300,
                "complex": 375,
                "enteringScreen": 225,
                "leavingScreen": 195
            }
        },
        "zIndex": {
            "mobileStepper": 1000,
            "fab": 1050,
            "speedDial": 1050,
            "appBar": 1100,
            "drawer": 1200,
            "modal": 1300,
            "snackbar": 1400,
            "tooltip": 1500
        }
    }
);

const App = (props) => {
    //let isBing = (typeof navigator !== 'undefined') ? (navigator.userAgent && !!navigator.userAgent.match(/bing/i)) : false
    //console.log('aqui->'+navigator.userAgent)

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    if (app.name && typeof window !== 'undefined') {
        getAnalytics(app);
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh'
                    }}
                >
                    <CssBaseline />
                    <Header {...props} />
                    <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 3, pb: 0, px: 3 }}>
                        <AdSense.Google
                            client='ca-pub-2605486701851317'
                            slot='3427166936'
                            style={{ display: 'block' }}
                            format='auto'
                            responsive='true'
                            layoutKey='-gw-1+2a-9x+5c'
                        />
                    </Container>

                    <Routes {...props} />
                    <Footer {...props} />
                </Box>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default App