const parseTimerFromPath = (props) => {
    try {
        let paramPath = props.location.pathname.split('/')[2]
        let timerFromPath = { hour: 0, minute: 0, second: 0 }
        if (paramPath) {
            paramPath = decodeURI(paramPath)
            paramPath = paramPath.toLowerCase()
            let tmpParam = paramPath.split('-')
            if (tmpParam.length !== 3 && tmpParam.length !== 5 && tmpParam.length !== 7) {
                throw new Error('Invalid Parameters')
            }
            if (isNaN(tmpParam[0]) || parseInt(tmpParam[0]) === 0) {
                throw new Error('Invalid Parameters')
            }
            if ((tmpParam.length === 5 || tmpParam.length === 7) && (isNaN(tmpParam[2]) || parseInt(tmpParam[2]) === 0)) {
                throw new Error('Invalid Parameters')
            }
            if (tmpParam.length === 7 && (isNaN(tmpParam[4]) || parseInt(tmpParam[4]) === 0)) {
                throw new Error('Invalid Parameters')
            }
            if (tmpParam[tmpParam.length - 1] !== 'timer') {
                throw new Error('Invalid Parameters')
            }
            if (tmpParam.length === 3) {
                tmpParam[0] = parseInt(tmpParam[0])
            } else if (tmpParam.length === 3) {
                tmpParam[0] = parseInt(tmpParam[0])
                tmpParam[2] = parseInt(tmpParam[2])
            } else {
                tmpParam[0] = parseInt(tmpParam[0])
                tmpParam[2] = parseInt(tmpParam[2])
                tmpParam[4] = parseInt(tmpParam[4])
            }

            if (tmpParam.length === 3 && ( //ex: 5-minutes-timer
                (tmpParam[0] === 1 && (tmpParam[1] !== 'minute' && tmpParam[1] !== 'hour' && tmpParam[1] !== 'second')) ||
                (tmpParam[0] > 1 && (tmpParam[1] !== 'minutes' && tmpParam[1] !== 'hours' && tmpParam[1] !== 'seconds')))) {
                throw new Error('Invalid Parameters')
            }
            if (tmpParam.length === 5 && ( //ex: 5-minutes-30-seconds-timer
                (
                    (tmpParam[0] === 1 && (tmpParam[1] !== 'minute' && tmpParam[1] !== 'hour')) ||
                    (tmpParam[0] > 1 && (tmpParam[1] !== 'minutes' && tmpParam[1] !== 'hours'))
                ) || paramPath
                    ((tmpParam[1] === 'minute' || tmpParam[1] === 'minutes') &&
                        ((tmpParam[2] === 1 && tmpParam[3] !== 'second') || (tmpParam[2] > 1 && tmpParam[3] !== 'seconds'))
                    ) ||
                ((tmpParam[1] === 'hour' || tmpParam[1] === 'hours') &&
                    ((tmpParam[2] === 1 && (tmpParam[3] !== 'minute' && tmpParam[3] !== 'second')) || (tmpParam[2] > 1 && (tmpParam[3] !== 'minutes' && tmpParam[3] !== 'seconds')))
                )
            )) {
                throw new Error('Invalid Parameters')
            }
            if (tmpParam.length === 7 && ( //ex: 1-hour-5-minutes-30-seconds-timer
                (tmpParam[0] === 1 && tmpParam[1] !== 'hour') ||
                (tmpParam[0] > 1 && tmpParam[1] !== 'hours') ||
                (tmpParam[2] === 1 && tmpParam[3] !== 'minute') ||
                (tmpParam[2] > 1 && tmpParam[3] !== 'minutes') ||
                (tmpParam[4] === 1 && tmpParam[5] !== 'second') ||
                (tmpParam[4] > 1 && tmpParam[5] !== 'seconds')
            )) {
                throw new Error('Invalid Parameters')
            }
            if (tmpParam.length === 3) {
                if (tmpParam[1] === 'hour' || tmpParam[1] === 'hours')
                    timerFromPath = { ...timerFromPath, hour: tmpParam[0] };
                else if (tmpParam[1] === 'minute' || tmpParam[1] === 'minutes')
                    timerFromPath = { ...timerFromPath, minute: tmpParam[0] };
                else
                    timerFromPath = { ...timerFromPath, second: tmpParam[0] };
            } else if (tmpParam.length === 5) {
                if (tmpParam[1] === 'hour' || tmpParam[1] === 'hours')
                    timerFromPath = { ...timerFromPath, hour: tmpParam[0] };
                else if (tmpParam[1] === 'minute' || tmpParam[1] === 'minutes')
                    timerFromPath = { ...timerFromPath, minute: tmpParam[0] };

                if (tmpParam[3] === 'minute' || tmpParam[3] === 'minutes')
                    timerFromPath = { ...timerFromPath, minute: tmpParam[2] };
                else
                    timerFromPath = { ...timerFromPath, second: tmpParam[2] };

            } else {
                timerFromPath = { ...timerFromPath, hour: tmpParam[0], minute: tmpParam[2], second: tmpParam[4] };
            }
            if (timerFromPath.hour > 24) {
                throw new Error('Invalid Parameters')
            }

            // --- limit times ---
            if (tmpParam.length === 3) {
                if (tmpParam[1] === 'hour' || tmpParam[1] === 'hours') {
                    let hoursOk = [1, 2, 5]
                    if (!hoursOk.includes(parseInt(tmpParam[0]))) {
                        throw new Error('limit reached')
                    }
                }
                if (tmpParam[1] === 'minute' || tmpParam[1] === 'minutes') {
                    let minutesOk = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 18, 19, 20, 22, 23, 25, 26, 28, 29, 30, 31, 35, 36, 39, 40, 43, 45, 50]
                    if (!minutesOk.includes(parseInt(tmpParam[0]))) {
                        throw new Error('limit reached')
                    }
                }
                if (tmpParam[1] === 'second' || tmpParam[1] === 'seconds') {
                    throw new Error('limit reached')
                }
            }
            if (tmpParam.length === 5) {
                throw new Error('limit reached')
            }
        }
        return timerFromPath
    } catch (e) {
        return false
    }
}

export default parseTimerFromPath;