import React from 'react'
import { FormattedMessage } from 'react-intl';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Divider, List, ListItem } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit"
        href="https://online-timer.me/"
        sx={{
          textDecoration: 'none',
          fontWeight: '700'
        }}>
        online-timer.me
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const footers = [
  { id: 'footer.terms', href: '/terms' },
  { id: 'footer.privacy', href: '/privacy' },
];

const footer = (props) => {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 'auto',
        // mt: 20,
        // py: [0, 1],
      }}
    >
      <List sx={{ flexDirection: 'row', display: 'inline-flex', padding: 0 }} >
        {footers.map((footer, k) => (
          <React.Fragment key={footer.id}>
            <ListItem sx={{ px: 1, py: 0, width: 'auto' }}>
              <Link href={footer.href} rel="nofollow sponsored" color="text.secondary" sx={{ fontSize: '0.675rem' }}>
                <FormattedMessage id={footer.id} />
              </Link>
            </ListItem>
            {k + 1 === footers.length ? '' : <Divider orientation='vertical' px={1} sx={{ borderBottomWidth: '15px' }} />}
          </React.Fragment>
        ))}
      </List>
      <Copyright sx={{ mt: 0 }} />
    </Container>
  )

}

export default footer